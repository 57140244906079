import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Container from 'react-bootstrap/Container'
class NotFound extends React.Component {
  render() {
    return (
      <Layout>
        <Seo title="404" />
        <Container className="section">
          <h1>Page not found</h1>
          <p>Please return to the main site.</p>
        </Container>
      </Layout>
    )
  }
}

export default NotFound
